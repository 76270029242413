<template>
  <mdb-container>
    <div class="text-right">
      <router-link to="/admin/youtubeManageList">
        <mdb-btn outline="info" size="md" class="my-3" icon="arrow-left">
          뒤로가기
        </mdb-btn>
      </router-link>
    </div>
    <mdb-card>
      <h3 class="card-header unique-color white-text text-center font-weight-bold text-uppercase py-2 ">영상 수정</h3>
      <div class="card-body">
        <label for="orderNumber" class="grey-text">순번</label>
        <input type="text" id="orderNumber" class="form-control" v-model="order_number">
        <br>
        <label for="title" class="grey-text">타이틀</label>
        <input type="text" id="title" class="form-control" v-model="title">
        <br>
        <label for="video_id" class="grey-text">video ID</label>
        <input type="text" id="video_id" class="form-control" v-model="video_id">
        <br>
        <!--        <label for="url" class="grey-text">노출 유무</label>-->
        <!--        <mdb-form-inline>-->
        <!--          <mdb-input type="radio" id="option5-1" name="groupOfMaterialRadios2" radioValue="true" v-model="show"-->
        <!--                     label="true" @input="handleRadioChange"/>-->
        <!--          <mdb-input type="radio" id="option5-2" name="groupOfMaterialRadios2" radioValue="false" v-model="show"-->
        <!--                     label="false" checked @input="handleRadioChange"/>-->
        <!--        </mdb-form-inline>-->
        <div class="text-center mt-1">
          <mdb-btn outline="success" @click="onUpload()" icon="pen">수정</mdb-btn>
        </div>
        <!-- Default form contact -->
      </div>
      <!-- Card body -->
    </mdb-card>
  </mdb-container>
</template>

<script>
import {mdbCard, mdbContainer, mdbBtn,} from 'mdbvue';
import firebase from "firebase/app";

export default {
  name: "YoutubeUpdate",
  data() {
    return {
      id: this.$route.query.id,
      order_number: '',
      video_id: '',
      title: "",
      show: false,
    }
  },
  components: {
    mdbCard,
    mdbContainer,
    mdbBtn,
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    onLoadData() {
      const db = firebase.firestore();
      const self = this;

      db.collection("Youtube")
          .doc(self.id).get().then(function (querySnapshot) {
        const _data = querySnapshot.data();
        self.order_number = _data.order_number;
        self.title = _data.title;
        self.show = _data.show;
        self.video_id = _data.video_id;

      });
    },
    handleRadioChange(value) {
      const self = this;
      self.show = value === "true";
    },
    getSelectValue(value) {
      const self = this;
      self.fields = value;
    },
    onUpload() {
      const self = this;
      const db = firebase.firestore();

      if (self.order_number === '') {
        self.order_number = 0
      }

      const data = {
        order_number: self.order_number,
        show: self.show,
        title: self.title,
        video_id: self.video_id,
        reg_date: firebase.firestore.Timestamp.fromDate(new Date()),
      }

      db.collection('Youtube').doc(self.id)
          .update(data)
          .then(() => {
            console.log("Document successfully updated!");
            alert('영상 등록이 완료되었습니다.');
            this.$router.push('/admin/youtubeManageList').catch((err) => {
              console.log(err)
            })
          })
          .catch((error) => {
            console.error("Error updating document: ", error);
          });
    },
  }
}
</script>

<style scoped>

</style>